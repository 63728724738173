$fontSizeList: (
    '400p' : 1rem,
    '1280p': 0.8rem,
    '1920p': 1rem,
    '2560p': 1.2rem,
    '3840p': 2rem
) !default;

$midFontSizeList: (
    '400p' : 1.1rem,
    '1280p': 1rem,
    '1920p': 1.2rem,
    '2560p': 1.4rem,
    '3840p': 2.4rem
) !default;

$bigFontSizeList: (
    '400p' : 1.2rem,
    '1280p': 1.4rem,
    '1920p': 1.8rem,
    '2560p': 2.4rem,
    '3840p': 4rem
) !default;

$xxxlFontSizeList: (
    '1280p': 3rem,
    '1920p': 4rem,
    '2560p': 5rem,
    '3840p': 8rem
) !default;

$breakpoints: (
    '400p' : 300px,
    '1280p': 1000px,
    '1920p': 1800px,
    '2560p': 2400px,
    '3840p': 3600px
) !default;


@font-face {
    font-family: 'Pretendard';
    //src: url('../../Fonts/Pretendard-Regular.woff');
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-display: swap;
};

$fontFamily: 'Pretendard';

// 공통컴포넌트(헤더, 소제목, 푸터) Height조절
$headerHeight:100px;

$subTitleHeight:200px;

$footerHeight:110px;

@mixin flexCentre() {
    display         : flex;
    justify-content : center;
    align-items     : center;
};

@mixin mobileStyle() {
    @media(min-width: 300px) and (max-width: 900px) {
        @content
    }
};

@mixin dynamic($breakpoint) {
    @if map-has-key($breakpoints,$breakpoint) {
        @media(min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`." + "Available breakpoints are: #{map-keys($breakpoints)}."
    }
};

@function getFont($key) {
    @return map-get($fontSizeList,$key);
}

@function getMidFont($key) {
    @return map-get($midFontSizeList,$key);
}

@function getBigFont($key) {
    @return map-get($bigFontSizeList,$key);
}

@function getMassiveFont($key) {
    @return map-get($xxxlFontSizeList,$key);
}


@mixin fontTitle() {
    //font-weight: bold;
    @include dynamic('400p') {
        font-size: getBigFont('400p')
    }
    @include dynamic('1280p') {
        font-size: getBigFont('1280p')
    }
    @include dynamic('1920p') {
        font-size: getBigFont('1920p')
    }
    @include dynamic('2560p') {
        font-size: getBigFont('2560p')
    }
    @include dynamic('3840p') {
        font-size: getBigFont('3840p')
    }
};

@mixin fontNormal() {
    //font-weight: normal;
    @include dynamic('400p') {
        font-size: getFont('400p')
    }
    @include dynamic('1280p') {
        font-size: getFont('1280p')
    }
    @include dynamic('1920p') {
        font-size: getFont('1920p')
    }
    @include dynamic('2560p') {
        font-size: getFont('2560p')
    }
    @include dynamic('3840p') {
        font-size: getFont('3840p')
    }
};

@mixin fontHighlight() {
    @include dynamic('400p') {
        font-size: getMidFont('400p')
    }
    @include dynamic('1280p') {
        font-size: getMidFont('1280p')
    }
    @include dynamic('1920p') {
        font-size: getMidFont('1920p')
    }
    @include dynamic('2560p') {
        font-size: getMidFont('2560p')
    }
    @include dynamic('3840p') {
        font-size: getMidFont('3840p')
    }
}

@mixin fontXXXL() {
    @include dynamic('1280p') {
        font-size: getMassiveFont('1280p')
    }
    @include dynamic('1920p') {
        font-size: getMassiveFont('1920p')
    }
    @include dynamic('2560p') {
        font-size: getMassiveFont('2560p')
    }
    @include dynamic('3840p') {
        font-size: getMassiveFont('3840p')
    }
}

$wrapperPad: 2rem 0 2rem 0;

.wrapper {
    @include flexCentre();
    flex-direction: column;
    justify-content: flex-start !important;
    //padding: $wrapperPad;
    width: 100%;
    height: auto;
    position: relative;
    min-height: calc(100vh - #{$headerHeight} - #{$subTitleHeight} - #{$footerHeight} - 3rem);
}