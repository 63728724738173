@import './Page/styles/global.module';

:root {
    font-family: 'Pretendard';
    font-size: 16px;
}

.Global {
    min-height: 100vh;
    position: relative;
}

// $fontSizeList: (
//     '1280p': 0.9rem,
//     '1920p': 1.2rem,
//     '2560p': 1.2rem,
//     '3840p': 2rem
// ) !default;

// $midFontSizeList: (
//     '1280p': 1.1rem,
//     '1920p': 1.4rem,
//     '2560p': 1.4rem,
//     '3840p': 2.4rem
// ) !default;

// $bigFontSizeList: (
//     '1280p': 1.8rem,
//     '1920p': 2.4rem,
//     '2560p': 2.4rem,
//     '3840p': 4rem
// ) !default;

// $breakpoints: (
//     '1280p': 1000px,
//     '1920p': 1800px,
//     '2560p': 2400px,
//     '3840p': 3600px
// ) !default;

// @function getFont($key) {
//     @return map-get($fontSizeList,$key);
// }

// @function getMidFont($key) {
//     @return map-get($midFontSizeList,$key);
// }

// @function getBigFont($key) {
//     @return map-get($bigFontSizeList,$key);
// }

// // 서브타이틀 | 문단 타이틀
// @mixin fontTitle() {
//     //font-weight: bold;
//     @include dynamic('1280p') {
//         font-size: getBigFont('1280p')
//     }
//     @include dynamic('1920p') {
//         font-size: getBigFont('1920p')
//     }
//     @include dynamic('2560p') {
//         font-size: getBigFont('2560p')
//     }
//     @include dynamic('3840p') {
//         font-size: getBigFont('3840p')
//     }
// };

// // 보통 사이즈 | 메뉴 사이즈
// @mixin fontNormal() {
//     //font-weight: normal;
//     @include dynamic('1280p') {
//         font-size: getFont('1280p')
//     }
//     @include dynamic('1920p') {
//         font-size: getFont('1920p')
//     }
//     @include dynamic('2560p') {
//         font-size: getFont('2560p')
//     }
//     @include dynamic('3840p') {
//         font-size: getFont('3840p')
//     }
// };

// // 글씨 하이라이트
// @mixin fontHighlight() {
//     @include dynamic('1280p') {
//         font-size: getMidFont('1280p')
//     }
//     @include dynamic('1920p') {
//         font-size: getMidFont('1920p')
//     }
//     @include dynamic('2560p') {
//         font-size: getMidFont('2560p')
//     }
//     @include dynamic('3840p') {
//         font-size: getMidFont('3840p')
//     }
// };

// @mixin dynamic($breakpoint) {
//     @if map-has-key($breakpoints,$breakpoint) {
//         @media(min-width: map-get($breakpoints, $breakpoint)) {
//             @content;
//         }
//     }
//     @else {
//         @warn "Unfortunately, no value could be retrieved from `#{$brekapoint}`." + "Available breakpoints are: #{map-keys($breakpoints)}."
//     }
// };

@include mobileStyle {
    .headerTag {
        position: sticky;
        top: 0;
        height: #{$headerHeight};
        z-index: 5;
        .Header {
            
            @include flexCentre();
            flex-direction: column;
            width: 100%; 
            max-width: 100%;
            min-height: 120px;
            max-height: 220px !important;
            background-color: white;
            z-index: 5;
            margin: 0 !important;
    
            .Signature_blank-wrap {
                @include flexCentre();
                margin: 0 !important;
                width: 100%;
                height: 80px;
                padding: 20px 0 20px 0;
                flex-direction: column;
    
                .Signature-wrap {
                    @include flexCentre();
                    img {
                        min-width: 156px;
                        min-height: 54px;
                    }
                }

                .mobile-menu-btn{
                    position: absolute;
                    top: 92px;
                    height: auto !important; 
                    width: 135px;
                    text-align: center;
                    // border: 1px solid black;
                    // border-radius: 5px;
                    visibility: visible !important;
                }
                .mobile-menu-list{
                    position: fixed;
                    top:110px;
                    background-color: white;
                    width:130px;
                    text-align: center;
                    border: solid;
                    border-color: white;
                    border-radius: 15px;


                }

    
                // &:hover {
                //     ~ .Menu-wrap {
                //         padding: 0 0 20px 0 !important;
                //         visibility: visible !important;
                //         height: 80px !important;
                //     }
                // }
            }
            .scroll-container{
                margin: 0;
                position:fixed;
                bottom:5%;
                right:5%; 
                width:30px;
                height:30px;
                background-color:#999999;
            }
            .Menu-wrap {
                margin: 0 !important;
                @include flexCentre();
                flex-direction: column;
                width: 100%;
                max-width: 100%;
                padding: 0 ;
                visibility: hidden ;
                height: 0;
    
                div {
                    width: 100%;
                    @include flexCentre();
                    white-space: nowrap;
                }
    
                a {
                    text-decoration-line: none;
                }
    
                .About{
                    margin: 0 !important;
                    &Text{
                        color:black;
                        &:hover{color:#1359b6};
                    }
                    &TextClicked{
                        color:#1359b6;
                    }
                }
                .Biz{
                    margin: 0 !important;
                    &Text{
                        color:black;
                        &:hover{color:#1359b6};
                    }
                    &TextClicked{
                        color:#1359b6;
                    }
                }
                .Rnd{
                    margin: 0 !important;
                    &Text{
                        color:black;
                        &:hover{color:#1359b6};
                    }
                    &TextClicked{
                        color:#1359b6;
                    }
                }
                .Career{
                    margin: 0 !important;
                    &Text{
                        color:black;
                        &:hover{color:#1359b6};
                    }
                    &TextClicked{
                        color:#1359b6;
                    }
                }
                .Contact{
                    margin: 0 !important;
                    &Text{
                        color:black;
                        &:hover{color:#1359b6};
                    }
                    &TextClicked{
                        color:#1359b6;
                    }
                }
            }
        }
    }
    

    .SubTitle {
        $BackGroundIMG: './IMG/Common/Background_Subtitle_dark.jpeg';
        font-family: 'Pretendard';
        background-repeat: no-repeat;
        background-position: 20% 10%;
        background-size: cover;
        background-image: url#{$BackGroundIMG};
        color:white;
        height: 100px !important;
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0 !important;
        justify-content: center;
        .Contents-wrap{
            @include flexCentre();
            .Contents_Subtitle{
                @include fontTitle();
                @include flexCentre();
                line-height: 1.1rem;
                width: 50% !important;
                margin: 0 !important;
                
            }
            .Contents_GoToMain{
                @include flexCentre();
                justify-content: center !important;
                width: 50% !important;
                margin: 0 !important;
                @include fontTitle();
                a {
                    text-decoration-line: none;
                    color: white;
                }

                
            }
    }
    }


    .footer{
        width: 100%;
        height: calc(#{$footerHeight} / 2) !important;
            background-color: #303749;
        
        .footer_container{
            margin: 0% 5% 0% 5% !important;
            width: 60%;
            padding: 1% 0% 0% 0%;
            display: flex;
            flex-direction: column;
            align-content: center;
            .logo_contact_wrap{
                display: flex;
                flex-direction: row;
                height: 50px;
                
                .logo{
                    display: none !important;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    margin-right: 5em;
                    width:8rem;
                }
                .contact_contents_copyright_wrap{
                    width: 100%;
                    font-size: 0.6rem !important;
                    display: flex;
                    flex-direction: column;
                    white-space:nowrap;
                    justify-content: space-evenly;
                    .contents_wrap{                  
                        display: flex;
                        flex-direction: column !important;
                        justify-content: space-evenly;
                        .company_title_desc_wrap{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            .company_title{
                                margin-right: 1%;
                                color: white;
                            }
                            .company_desc{
                                display: flex;
                                align-items: flex-end;
                                color: rgb(200, 200, 200);
                            }
                        }
                        .lab_title_desc_wrap{
                            display: flex;
                            flex-direction: row;
                            .lab_title{
                                margin-right: 1%;
                                color: white;
                            }
                            .lab_desc{
                                display: flex;
                                align-items: flex-end;
                                color: rgb(200, 200, 200);
                            }
                        }
                    }
                    .copyright{
                        color: rgb(200, 200, 200);
                    }
                }
            
            }
            .icons_wrap{
                display: none !important;
                flex-direction: row;
                align-self: flex-end;
                font-size: 0;
                line-height: 0;
                color: transparent;
            
                .icon1{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzk5OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4xNDMgMTcuMTEySDEzLjU5di01LjU1OWMwLTEuMzI1LS4wMjMtMy4wMy0xLjg0Ni0zLjAzLTEuODQ4IDAtMi4xMyAxLjQ0NC0yLjEzIDIuOTM2djUuNjUzSDYuMDY2VjUuNjgyaDMuNDA1djEuNTYzaC4wNWMuNDczLS44OTkgMS42MzMtMS44NDcgMy4zNi0xLjg0NyAzLjU5OCAwIDQuMjYyIDIuMzY3IDQuMjYyIDUuNDQ1djYuMjY5ek0yLjA2IDQuMTJDLjkyMSA0LjEyIDAgMy4xOTcgMCAyLjA2IDAgLjkyMy45MiAwIDIuMDYxIDAgMy4xOTcgMCA0LjEyLjkyNCA0LjEyIDIuMDZjMCAxLjEzNy0uOTIzIDIuMDYtMi4wNTkgMi4wNnptMS43NzcgMTIuOTlILjI4MlY1LjY4MmgzLjU1NnYxMS40M3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzIyIC0zNjQ1KSB0cmFuc2xhdGUoMCAzNTU2KSB0cmFuc2xhdGUoMTMyMiA4OSkgdHJhbnNsYXRlKDMuNDI5IDIuODU3KSIvPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
                    background-repeat: no-repeat;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    // background-color: #1359b6;    
                }
                .icon2{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzk5OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEyLjg4OCA1LjcxM2wuMjI3LjAwMmMxLjE0OC4wMDcgMy44ODQuMDQgNS44NTguMTgzLjM1Ny4wNDIgMS4xMzcuMDQ1IDEuODMyLjc3My41NDguNTU2LjcyNiAxLjgxNi43MjYgMS44MTZsLjAwOS4wNzNjLjAzNi4zMTMuMTc0IDEuNi4xNzQgMi44ODZ2MS4zODhjMCAxLjQ4LS4xODMgMi45Ni0uMTgzIDIuOTZzLS4xNzggMS4yNi0uNzI2IDEuODE1Yy0uNjk1LjcyOC0xLjQ3NS43My0xLjgzMi43NzMtMi4yNjcuMTY0LTUuNTQuMTg3LTYuMjU5LjE5aC0uMTljLS41LS4wMDQtNC43OTMtLjA1LTYuMTctLjE4Mi0uNDA3LS4wNzYtMS4zMi0uMDUzLTIuMDE2LS43ODEtLjU0OC0uNTU2LS43MjctMS44MTUtLjcyNy0xLjgxNXMtLjE4Mi0xLjQ4LS4xODItMi45NnYtMS4zODhjMC0xLjM1MS4xNTItMi43MDEuMTc5LTIuOTI2bC4wMDMtLjAzM3MuMTc5LTEuMjYuNzI3LTEuODE2Yy42OTUtLjcyOCAxLjQ3NS0uNzMxIDEuODMyLS43NzMuNTg1LS4wNDMgMS4yMzctLjA3NSAxLjg5NC0uMWwuNDk0LS4wMThjMS4zOTUtLjA0NiAyLjc0Ny0uMDYgMy40Ny0uMDY1bC4yMjctLjAwMmguNjMzem0tMi4yMDUgMy42NjN2NS4xMzlsNC45NDEtMi41Ni00Ljk0MS0yLjU3OXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDQyIC0zNjQ1KSB0cmFuc2xhdGUoMCAzNTU2KSB0cmFuc2xhdGUoMTQ0MiA4OSkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
                    background-repeat: no-repeat;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    // background-color: red;
    
                }
            }
        }
    }
}










.headerTag {
    position: sticky;
    top: 0;
    height: #{$headerHeight};
    z-index: 5;

    .Header{  
        display: flex;
        width: 100%; 
        height:#{$headerHeight};
        align-items:center;
        background-color: white;
        
        .Signature_blank-wrap{
            margin: 0% 0% 0% 20%;
            width: 100%; 
            .Signature-wrap{
                //Signature 사이즈 조절
                img{
                    width: 156px;
                    height: 54px;
                }
            }
            .mobile-menu-btn{
                visibility: hidden;
                height: 0;
            }

        }
        .scroll-container{
            margin: 0;
            position:fixed;
            bottom:5%;
            right:5%; 
            width:50px;
            height:50px;
            background-color:#9b9494c5;
            cursor: pointer;
            :hover{
                background-color:hsla(0, 3%, 59%, 0.774);
            }
        }
        .Menu-wrap{
            margin: 0% 20% 0% 20%;
            display: flex;
            width: 100%;
            @include fontNormal();
            justify-content: end;
            div{
                display: flex;
                text-align: center;
                white-space: nowrap;
                }            
            }
            a{
                // color: black;
                text-decoration-line: none;
                
            }
            .About{
                margin-left: -15%;
                &Text{
                    color:black;
                    &:hover{color:#1359b6};
                }
                &TextClicked{
                    color:#1359b6;
                }
            }
            .Biz{
                margin-left: 5%;
                &Text{
                    color:black;
                    &:hover{color:#1359b6};
                }
                &TextClicked{
                    color:#1359b6;
                }
            }
            .Rnd{
                margin-left: 5%;
                &Text{
                    color:black;
                    &:hover{color:#1359b6};
                }
                &TextClicked{
                    color:#1359b6;
                }
            }
            .Career{
                margin-left: 5%;
                &Text{
                    color:black;
                    &:hover{color:#1359b6};
                }
                &TextClicked{
                    color:#1359b6;
                }
            }
            .Contact{
                margin-left: 5%;
                &Text{
                    color:black;
                    &:hover{color:#1359b6};
                }
                &TextClicked{
                    color:#1359b6;
                }
            }
            
    }
}


.SubTitle{
    $BackGroundIMG: './IMG/Common/Background_Subtitle_dark.jpeg';

    font-family: 'Pretendard';
    background-repeat: no-repeat;
    background-position: 20% 10%;
    background-size: cover;
    background-image: url($BackGroundIMG);
    color:white;
    height: #{$subTitleHeight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Contents-wrap{
        display: flex;
        .Contents_Subtitle{
            @include fontTitle();
            line-height: 1.1rem;
            width: 100%;
            margin: 0% 0% 0% 20%;
            
        }
        .Contents_GoToMain{
            @include fontNormal();
            display: flex;
            justify-content: end;
            width: 100%;
            margin: 0% 20% 0% 0%;
            font-size: 1rem;
            a {
                text-decoration-line: none;
                color: white;
            }

            
        }
        

    }
    
}

// Footer width 900-1900
@media(min-width: 900px) and (max-width: 1900px) {
    .footer{
        width: 100%;
        height: #{$footerHeight};
            background-color: #303749;
        .footer_container{
            margin: 0% 20% 0% 20%;
            width: 60%;
            padding: 1% 0% 0% 0%;
            display: flex;
            flex-direction: column;
            align-content: center;
            .logo_contact_wrap{
                display: flex;
                flex-direction: row;
                height: 50px;
                
                .logo{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    margin-right: 5em;
                    width:8rem;
    
                    img {
                        max-width: 100%;
                        max-height: 50px;
                    }
                }
                .contact_contents_copyright_wrap{
                    // @include fontNormal();
                    width: 100%;
                    font-size: 0.9rem;
                    display: flex;
                    flex-direction: column;
                    white-space:nowrap;
                    justify-content: space-evenly;
                    .contents_wrap{                  
                        display: flex !important;
                        flex-direction: column !important;
                        justify-content: space-evenly !important;
                        .company_title_desc_wrap{
                            margin-top: 30px;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            .company_title{
                                margin-right: 1%;
                                color: white;
                            }
                            .company_desc{
                                display: flex;
                                align-items: flex-end;
                                color: rgb(200, 200, 200);
                            }
                        }
                        .lab_title_desc_wrap{
                            margin-top: 5px;
                            display: flex;
                            flex-direction: row;
                            .lab_title{
                                margin-right: 1%;
                                color: white;
                            }
                            .lab_desc{
                                display: flex;
                                align-items: flex-end;
                                color: rgb(200, 200, 200);
                            }
                        }
                    }
                    .copyright{
                        margin-top: 5px;
                        color: rgb(200, 200, 200);
                    }
                }
            
            }
            .icons_wrap{
                display: flex;
                flex-direction: row;
                align-self: flex-end;
                font-size: 0;
                line-height: 0;
                color: transparent;
            
                .icon1{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzk5OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4xNDMgMTcuMTEySDEzLjU5di01LjU1OWMwLTEuMzI1LS4wMjMtMy4wMy0xLjg0Ni0zLjAzLTEuODQ4IDAtMi4xMyAxLjQ0NC0yLjEzIDIuOTM2djUuNjUzSDYuMDY2VjUuNjgyaDMuNDA1djEuNTYzaC4wNWMuNDczLS44OTkgMS42MzMtMS44NDcgMy4zNi0xLjg0NyAzLjU5OCAwIDQuMjYyIDIuMzY3IDQuMjYyIDUuNDQ1djYuMjY5ek0yLjA2IDQuMTJDLjkyMSA0LjEyIDAgMy4xOTcgMCAyLjA2IDAgLjkyMy45MiAwIDIuMDYxIDAgMy4xOTcgMCA0LjEyLjkyNCA0LjEyIDIuMDZjMCAxLjEzNy0uOTIzIDIuMDYtMi4wNTkgMi4wNnptMS43NzcgMTIuOTlILjI4MlY1LjY4MmgzLjU1NnYxMS40M3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzIyIC0zNjQ1KSB0cmFuc2xhdGUoMCAzNTU2KSB0cmFuc2xhdGUoMTMyMiA4OSkgdHJhbnNsYXRlKDMuNDI5IDIuODU3KSIvPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
                    background-repeat: no-repeat;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    // background-color: #1359b6;    
                }
                .icon2{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzk5OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEyLjg4OCA1LjcxM2wuMjI3LjAwMmMxLjE0OC4wMDcgMy44ODQuMDQgNS44NTguMTgzLjM1Ny4wNDIgMS4xMzcuMDQ1IDEuODMyLjc3My41NDguNTU2LjcyNiAxLjgxNi43MjYgMS44MTZsLjAwOS4wNzNjLjAzNi4zMTMuMTc0IDEuNi4xNzQgMi44ODZ2MS4zODhjMCAxLjQ4LS4xODMgMi45Ni0uMTgzIDIuOTZzLS4xNzggMS4yNi0uNzI2IDEuODE1Yy0uNjk1LjcyOC0xLjQ3NS43My0xLjgzMi43NzMtMi4yNjcuMTY0LTUuNTQuMTg3LTYuMjU5LjE5aC0uMTljLS41LS4wMDQtNC43OTMtLjA1LTYuMTctLjE4Mi0uNDA3LS4wNzYtMS4zMi0uMDUzLTIuMDE2LS43ODEtLjU0OC0uNTU2LS43MjctMS44MTUtLjcyNy0xLjgxNXMtLjE4Mi0xLjQ4LS4xODItMi45NnYtMS4zODhjMC0xLjM1MS4xNTItMi43MDEuMTc5LTIuOTI2bC4wMDMtLjAzM3MuMTc5LTEuMjYuNzI3LTEuODE2Yy42OTUtLjcyOCAxLjQ3NS0uNzMxIDEuODMyLS43NzMuNTg1LS4wNDMgMS4yMzctLjA3NSAxLjg5NC0uMWwuNDk0LS4wMThjMS4zOTUtLjA0NiAyLjc0Ny0uMDYgMy40Ny0uMDY1bC4yMjctLjAwMmguNjMzem0tMi4yMDUgMy42NjN2NS4xMzlsNC45NDEtMi41Ni00Ljk0MS0yLjU3OXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDQyIC0zNjQ1KSB0cmFuc2xhdGUoMCAzNTU2KSB0cmFuc2xhdGUoMTQ0MiA4OSkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
                    background-repeat: no-repeat;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    // background-color: red;
    
                }
            }
        }
    }
    
    .h1 {
        // @include fontHighlight();
        font-size : 25px;
        font-weight : bold;
        color : #545454;
        white-space: pre;
    }
    
    .div_float_left {
        float: left;
    }
}

// Footer width 1900+
.footer{
    width: 100%;
    height: #{$footerHeight};
        background-color: #303749;
    .footer_container{
        margin: 0% 20% 0% 20%;
        width: 60%;
        padding: 1% 0% 0% 0%;
        display: flex;
        flex-direction: column;
        align-content: center;
        .logo_contact_wrap{
            display: flex;
            flex-direction: row;
            height: 50px;
            
            .logo{
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                margin-right: 5em;
                width:8rem;

                img {
                    max-width: 100%;
                    max-height: 50px;
                }
            }
            .contact_contents_copyright_wrap{
                // @include fontNormal();
                width: 100%;
                font-size: 0.9rem;
                display: flex;
                flex-direction: column;
                white-space:nowrap;
                justify-content: space-evenly;
                .contents_wrap{                  
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    .company_title_desc_wrap{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        .company_title{
                            margin-right: 1%;
                            color: white;
                        }
                        .company_desc{
                            display: flex;
                            align-items: flex-end;
                            color: rgb(200, 200, 200);
                        }
                    }
                    .lab_title_desc_wrap{
                        display: flex;
                        flex-direction: row;
                        .lab_title{
                            margin-right: 1%;
                            color: white;
                        }
                        .lab_desc{
                            display: flex;
                            align-items: flex-end;
                            color: rgb(200, 200, 200);
                        }
                    }
                }
                .copyright{
                    color: rgb(200, 200, 200);
                }
            }
        
        }
        .icons_wrap{
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            font-size: 0;
            line-height: 0;
            color: transparent;
        
            .icon1{
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzk5OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4xNDMgMTcuMTEySDEzLjU5di01LjU1OWMwLTEuMzI1LS4wMjMtMy4wMy0xLjg0Ni0zLjAzLTEuODQ4IDAtMi4xMyAxLjQ0NC0yLjEzIDIuOTM2djUuNjUzSDYuMDY2VjUuNjgyaDMuNDA1djEuNTYzaC4wNWMuNDczLS44OTkgMS42MzMtMS44NDcgMy4zNi0xLjg0NyAzLjU5OCAwIDQuMjYyIDIuMzY3IDQuMjYyIDUuNDQ1djYuMjY5ek0yLjA2IDQuMTJDLjkyMSA0LjEyIDAgMy4xOTcgMCAyLjA2IDAgLjkyMy45MiAwIDIuMDYxIDAgMy4xOTcgMCA0LjEyLjkyNCA0LjEyIDIuMDZjMCAxLjEzNy0uOTIzIDIuMDYtMi4wNTkgMi4wNnptMS43NzcgMTIuOTlILjI4MlY1LjY4MmgzLjU1NnYxMS40M3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzIyIC0zNjQ1KSB0cmFuc2xhdGUoMCAzNTU2KSB0cmFuc2xhdGUoMTMyMiA4OSkgdHJhbnNsYXRlKDMuNDI5IDIuODU3KSIvPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
                background-repeat: no-repeat;
                background-position: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                width: 30px;
                height: 30px;
                // background-color: #1359b6;    
            }
            .icon2{
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzk5OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEyLjg4OCA1LjcxM2wuMjI3LjAwMmMxLjE0OC4wMDcgMy44ODQuMDQgNS44NTguMTgzLjM1Ny4wNDIgMS4xMzcuMDQ1IDEuODMyLjc3My41NDguNTU2LjcyNiAxLjgxNi43MjYgMS44MTZsLjAwOS4wNzNjLjAzNi4zMTMuMTc0IDEuNi4xNzQgMi44ODZ2MS4zODhjMCAxLjQ4LS4xODMgMi45Ni0uMTgzIDIuOTZzLS4xNzggMS4yNi0uNzI2IDEuODE1Yy0uNjk1LjcyOC0xLjQ3NS43My0xLjgzMi43NzMtMi4yNjcuMTY0LTUuNTQuMTg3LTYuMjU5LjE5aC0uMTljLS41LS4wMDQtNC43OTMtLjA1LTYuMTctLjE4Mi0uNDA3LS4wNzYtMS4zMi0uMDUzLTIuMDE2LS43ODEtLjU0OC0uNTU2LS43MjctMS44MTUtLjcyNy0xLjgxNXMtLjE4Mi0xLjQ4LS4xODItMi45NnYtMS4zODhjMC0xLjM1MS4xNTItMi43MDEuMTc5LTIuOTI2bC4wMDMtLjAzM3MuMTc5LTEuMjYuNzI3LTEuODE2Yy42OTUtLjcyOCAxLjQ3NS0uNzMxIDEuODMyLS43NzMuNTg1LS4wNDMgMS4yMzctLjA3NSAxLjg5NC0uMWwuNDk0LS4wMThjMS4zOTUtLjA0NiAyLjc0Ny0uMDYgMy40Ny0uMDY1bC4yMjctLjAwMmguNjMzem0tMi4yMDUgMy42NjN2NS4xMzlsNC45NDEtMi41Ni00Ljk0MS0yLjU3OXoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDQyIC0zNjQ1KSB0cmFuc2xhdGUoMCAzNTU2KSB0cmFuc2xhdGUoMTQ0MiA4OSkiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
                background-repeat: no-repeat;
                background-position: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                width: 30px;
                height: 30px;
                // background-color: red;

            }
        }
    }
}

.h1 {
    // @include fontHighlight();
    font-size : 25px;
    font-weight : bold;
    color : #545454;
    white-space: pre;
}

.div_float_left {
    float: left;
}
