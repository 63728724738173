@import './global.module';


* {
    font-family: 'Pretendard' !important;
}

$boxWidth: 60%;
$mobileBoxWidth: 80%;

@include mobileStyle() {

    .contentBox {
        @include flexCentre();
        flex-direction: column;
        width: 100%;
        margin: 2rem 0 2rem 0;

        .subTitle {
            @include fontTitle();
            @include flexCentre();
            font-weight: bold;
            justify-content: flex-start !important;
            font-family: $fontFamily;
            width: $mobileBoxWidth;
            height: auto;
            margin-bottom: 2rem;
            //background: lightgrey;
            
        }
    
        .subTitle2 {
            @include fontTitle();
            @include flexCentre();
            font-weight: bold;
            font-family: $fontFamily;
            width: $mobileBoxWidth;
            height: auto;
            //background: lightgrey;
            margin-bottom: 2rem;
        }

        .subTitle_half {
            font-weight: bold;
            font-family: $fontFamily;
            font-size: 1rem;
            width: $mobileBoxWidth;
            height: auto;
            span {
                white-space: pre-line;
                b {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
        }

        .textContent {
            @include fontNormal();
            @include flexCentre();
            justify-content: flex-start !important;
            font-family: $fontFamily;
            width: $mobileBoxWidth;
            height: auto;
            margin: 0 0 2rem 0;
            //background: lightgrey;
            
            span {
                line-height: 2;
                white-space: pre-line;
    
                strong {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
        }

        .imageContent {
            width: $mobileBoxWidth;
            height: auto;
            @include flexCentre();
            flex-direction: column;
    
            img {
                max-height: 100vh;
                //min-width: 100%;
                max-width: 100%;
            }
            margin: 0 0 1rem 0;
    
        }

        .imageTextContent {
            width: $mobileBoxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: column;
            justify-content: flex-start !important;
    
            .imageWrap {
                max-width: 100%;
                height: auto;    
                position: relative;

                .textBox {
                    width: 100%;
                    height: auto;
                    @include flexCentre();
                    position: absolute;
                    bottom: 1rem;
                    
                    span {
                        width: $mobileBoxWidth;
                        @include fontNormal();
                        cursor: pointer;
                        text-align: center;
                        font-weight: bold;
                        color: lightslategray;
                
                        &:hover {
                            color: black;
                        }
                    }
                
                    //margin: 0 0 2rem 0;
                }
    
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 600px;
                    object-fit: cover;
                }
            }
    
            .textWrap {
                max-width: 100%;
                height: auto;
                max-height: 800px;
                padding-top: 2rem;
                @include fontNormal();

                img {
                    max-width: 100%;
                    height: auto;
                }
    
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
    
            
        }

        .imageTextContent_Career {
            width: $mobileBoxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: column;
            justify-content: flex-start !important;
    
            .imageWrap {
                max-width: 100%;
                height: auto;    
                position: relative;
    
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 600px;
                    object-fit: cover;
                }
            }
    
            .textWrap {
                max-width: 100%;
                height: auto;
                max-height: 800px;
                padding-top: 2rem;
                @include fontNormal();
    
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
    
            
        }

        .tabContent {
            @include flexCentre();
            flex-direction: column;
            width: $mobileBoxWidth;
            height: auto;
            margin: 0 0 2rem 0;
    
            .tabMenu {
                @include flexCentre();
                font-weight: bold;
                flex-direction: row;
                width: 100%;
                height: 100px;
                min-height: 70px;
                max-height: 100px;
                overflow-x: hidden;
                overflow-y:visible;
                margin: 0 0 2rem 0;
    
                ._disabled {
                    color: grey;
                    cursor: default;
                    user-select: none;
                    pointer-events: none;
                }
    
                .tabIcons {
                    @include flexCentre();
                    @include fontTitle();
                    width: 10%;
    
                    &:nth-child(odd) {
                        cursor: pointer;
                    }
    
                    &:nth-child(even) {
                        user-select: none;
                    }
                }
    
                .tabPseudo {
                    position: relative;
                    width: 80%;
                    height: 100px;
                    max-height: 1000px;
                    
                    @include flexCentre();
    
                    // &:before {
                    //     position: absolute;
                    //     content: '';
                    //     height: 0.5rem;
                    //     left: 0;
                    //     right: 50%;
                    //     background: steelblue;
                    //     z-index: 1;
                    // }
    
                    &:after {
                        position: absolute;
                        content: '';
                        height: 0.3rem;
                        left:0;
                        right: 0;
                        background: white;
                        box-shadow: 0rem 0 0.2rem 0rem rgba(0,0,0,0.5);
                        pointer-events: none;
                        user-select: none;
                        
                    }
                    
                    .pseudoWrap {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include flexCentre();
                        overflow-x: hidden;
                        //transform: translateX(33%);
                        transition: all 0.5s ease-in-out;
    
                        .indicator {
                            position: absolute;
                            @include flexCentre();
                            pointer-events: none;
                            user-select: none;
                            width: 1rem;
                            height: 1rem;
                            background: steelblue;
                            border: 0.2rem solid steelblue;
                            border-radius: 50%;
                            z-index: 2;
                            transition: all 0.5s ease-in-out;
                        }
                        
                        .indicatorBar {
                            position: absolute;
                            @include flexCentre();
                            pointer-events: none;
                            user-select: none;
                            left: -100%;
                            right: 50%;
                            height: 0.4rem;
                            background: steelblue;
                            z-index: 1;
                            transition: all 0.5s ease-in-out;
                        }
    
                        .tabBtn {
                            position: relative;
                            display: inline-block;
                            @include flexCentre();
                            @include fontNormal();
                            margin-bottom: 3.5rem;
                            min-width: 4rem;
                            z-index: 1;
                            cursor: pointer;
                            transition: all 0.5s ease-in-out;
                            
        
                            &:after {
                                cursor: pointer;
                                position: absolute;
                                content: '';
                                width: 1rem;
                                height: 1rem;
                                border-radius: 50%;
                                background: white;
                                z-index: 1;
                                border: 0.2rem solid steelblue;
                                margin-top: 3.5rem;
                                transition: all 0.5s ease-in-out;
                                
                            }
                        }
                    }
                       
                    
                }
            }
    
            .tabTimeline {
                position: relative;
                width: 100%;
                height: auto;
                margin: 0;
                padding: 0 2rem 0 2rem;
                @include flexCentre();
                flex-direction: column;
                // &::after {
                //     content: '';
                //     position: absolute;
                //     left: 50%;
                //     top:0;
                //     bottom:0;
                //     width: 0.1rem;
                //     //background:rgba(120,120,120, 0.6);
                //     background: black;
                // }
    
                ._toLeft {
                    margin-left:50%;
                    border-top-left-radius: 50px;
                    border-bottom-left-radius: 50px;
                }
    
                ._toRight {
                    
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
    
                .timelineContainer {
                    width: 50%;
                    height: auto;
                    @include flexCentre();
                    
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    position: relative;
                    box-sizing: border-box;
    
                    &:nth-child(odd) {
    
                        padding: 0 20px 0 0;
    
                        .timeMonth {
                            border-top-right-radius: 50px;
                            border-bottom-right-radius: 50px;
                        }
    
                        &:after {
                            content: '';
                            position: absolute;
                            height: 0.1rem;
                            background: black;
                            z-index: 1;
                            left:41.7rem;
                            right:0;
                        }
                    }
    
                    &:nth-child(even) {
                        padding: 0 0 0 20px;
    
                        .timeMonth {
                            border-top-left-radius: 50px;
                            border-bottom-left-radius: 50px;
                        }
    
                        &:after {
                            content: '';
                            position: absolute;
                            height: 0.1rem;
                            background: black;
                            z-index: 1;
                            left:0;
                            right:41.7rem;
                        }
                    }
    
                    .timeMonth {
                        width:30%;
                        background: rgba(70, 130, 180,1);
    
                        &:after {
                            content: '';
                            position: absolute;
                            width: 50px;
                            height: 50px;
                            border: 2px solid black;
                            border-radius: 50%;
    
                        }
                    }
    
                    .timeDesc {
                        width: 80%;
                        background: rgba(70, 130, 180,1);
                    }
    
                    span {
                        @include flexCentre();
                        flex-direction: column;
                        width: 100%;
                        height: 100px;
                        @include fontHighlight();
                        line-height: 2;
                        font-weight: bold;
                        color: white;
                    }
                }
    
                .timelineWrap {
                    width: 100%;
                    height: auto;
                    @include flexCentre();
                    animation: timelineAppear 0.5s ease-in-out forwards;
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                    position:relative;
                    padding: 0rem;
                    opacity: 0;
    
                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            animation-delay: $i * 0.1s
                        }
                    }
    
                    &:hover {
    
                        > .timelineDesc {
                            background: steelblue;
                            color: white;
                        }
    
                        > .timelineMonth {
                            animation: jello 0.9s both;
                        }
                        
                    }
                    
    
                    .timelineMonth {
                        @include flexCentre();
                        @include fontHighlight();
                        position: absolute;
                        //border-radius: 50%;
                        left: -1.5rem;
                        top: -2.5rem;
                        width: 4rem;
                        height: 4rem;
                        background: linear-gradient(135deg,steelblue 0%, rgb(199, 213, 224) 100%);
                        //border: 0.2rem solid steelblue;
                        border-radius: 2rem 2rem 0 2rem;
                        color: white;
                        user-select: none;
                        pointer-events: none;
                    }
    
                    .timelineDesc {
                        @include flexCentre();
                        flex-direction: column;
                        align-items: flex-start !important;
                        padding-left: 2rem;
                        @include fontNormal();
                        white-space: pre-line;
                        line-height: 2;
                        width: calc(100%);
                        min-height: 5rem;
                        background: white;
                        //border: 0.2rem solid steelblue;
                        margin-left: 1rem;
                        box-shadow: 0rem 0 0.3rem 0.1rem rgba(0,0,0,0.5);
                        user-select: none;
                        pointer-events: none;
                        border-radius: 5px;
                    }
                }
            }
        }

        .cardContainer {
            width: calc(100% - 4rem);
            height: auto;
            min-height: 400px;
            
            @include flexCentre();
            flex-direction: column;
    
            .cardUnit {
                @include flexCentre();
                flex-direction: column;
                width: 100%;
                height: 100%;
                min-height: 400px;
                margin: 0 2rem 0 2rem;
                box-sizing: border-box;
                cursor: pointer;
    
                &:hover {
                    & > .cardDesc {
    
                        background: steelblue;
                        color: white;
    
                        &:after {
                            background: steelblue;
                        }
                    } 
                }
                
    
                .cardImg {
                    user-select: none;
                    pointer-events: none;
                    width: 100%;
                    height: 80%;
                    box-sizing: border-box;
    
                    img {
                        width: 100%;
                        max-height: 320px;
                        object-fit: cover;
                        border: none;
                        display: block;
                    }

                    picture {
                        width: 100%;
                        max-height: 320px;
                        object-fit: cover;
                        border: none;
                        display: block;
                    }
                    
                }
    
                .cardDesc {
                    user-select: none;
                    pointer-events: none;
                    width: 100%;
                    height: 20%;
                    @include flexCentre();
                    flex-direction: column;
                    align-items: flex-start !important;
                    position: relative;
                    padding: 1.5rem;
                    box-sizing: border-box;
                    border: none;
                    transition: background-color 0.3s ease-in-out;
    
                    span {
                        margin-bottom: 1rem;
                        &:nth-child(1) {
                            @include fontHighlight();
                            font-weight: bold;
                        }
                        &:nth-child(2) {
                            @include fontNormal();
                        }
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        width: 2rem;
                        height: 2rem;
                        top: -1rem;
                        left: 2rem;
                        background: white;
                        transform: rotate(45deg);
                        transition: background-color 0.3s ease-in-out;
                        
                    }
                    
                }
            }
            .image_select {
                width : 100%;
            }
        }
        .imageAndText {
            @include fontNormal();
            @include flexCentre();
            width: 100%;
            height: auto;
            flex-wrap:wrap;
            .image_div {
                width:20%;
            }
            img {
                max-width: $boxWidth;
                min-height: 20vh;
                max-height: 100vh;
            }
        }
    }

    .imageBox {
        @include flexCentre();
        width: 100%;
        height: auto;
        flex-wrap:wrap;
        
        img {
            max-width: $mobileBoxWidth;
            max-height: 50vh;
        }

        picture {
            max-width: $mobileBoxWidth;
            max-height: 50vh;

            img, source {
                width: 100%;
                height: 100%;
            }
        }

        margin: 0 0 2rem 0;
    }

    .modalWrapper {
        @include flexCentre();
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100vh;
        top: 0;
        background: rgba(120,120,120, 0.6); 
    
        .modalBody {
            background: rgba(255,255,255,1);
            width: 80%;
            height: auto;
            border-radius: 16px;
            user-select: none;
            
            img {
                max-height: 80vh;
                max-width: 100%;
            }
        }
    }

    .cardSlide {
        width: 100%;
        height: 40vh;
        background: grey;
        position: relative;
        overflow: hidden;
    
        .cardBox {
            position: absolute;
            //transition: all 0.5s ease-in-out;
            white-space:nowrap;
            height: 100%;
    
            .slider {
                display: inline-flex;
                position: relative;
                width: 100vw;
                height: 100%;
    
                .titleDiv {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    @include flexCentre();
                    background: rgba(0,0,0,0.6);
    
                    span {
                        display: block;
                        white-space: pre-line;
                        font-weight:bold;
                        text-align: center;
                        width: 100%;
                        font-size: 30px;
                        color:white;
                        user-select: none;
                        transition: all 1s ease-in-out;
                        transition-delay: 1s;
                        //opacity: 0;
                    }
                }
                
    
                img {
                    display: inline-flex;
                    line-height: 2;
                    width: 100%;
                    min-height: 40vh;
                    max-height: calc(100vh - 100px);
                    object-fit:cover;  
                }

                picture {
                    display: inline-flex;
                    line-height: 2;
                    width: 100%;
                    min-height: calc(100vh - 100px);
                    max-height: calc(100vh - 100px);
                    object-fit:cover;  

                    img, source {
                        width: 100%;
                        height: 100%;
                    }
                }
            }    
    
            
        }
    
        .iconWrap {
            @include flexCentre();
            position: absolute;
            top: calc(50%);
            width: 35px;
            height: 35px;
            z-index:1;
            //background: white;
    
            &:nth-child(1) {
                left: 0;
            }
    
            &:nth-child(2) {
                right: 0;
            }
    
            .cardIcon {
                color: white;
                width: 100%;
                height: 100%;
                cursor: pointer;
                
                &:hover {
                    color: rgba(120,120,120, 0.6);
                }
            }
        }
        
        
    
        .cardTitle {
            @include flexCentre();
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(0,0,0,0.6);
    
            span {
                text-align: center;
                width: 50%;
                font-size: 70px;
                color:white;
            }
    
        }
    }
    .map_Summary {
        @include flexCentre();
        flex-direction: column;
        width: 100%;
        height: auto;
        // margin: 2rem 0 2rem 0;
        
        .subTitle {
            @include fontTitle();
            @include flexCentre();
            font-weight: bold;
            font-family: $fontFamily;
            width: $mobileBoxWidth;
            height: auto;
            // margin-bottom: 1rem;  
            span {
                white-space: pre-line;
                b {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
        }

    
        .imageTextContent {
            width: $mobileBoxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: row;
            justify-content: flex-start !important;
        }

    
        .text_MapContent {
            @include fontTitle();
            font-family: $fontFamily;
            width: $mobileBoxWidth;
            height: auto;
            margin-top: 2rem;
            .h1{
                @include fontTitle();
                font-weight: bold;
                color : #545454;
                font-family: $fontFamily;
                width: $mobileBoxWidth;
                height: auto;
                margin-top: 1rem;
                // margin-bottom: 1rem;  
                span {
                    white-space: pre-line;
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
            span {
                white-space: pre-line;
                display: flex;
                b {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
            .text_Area {
                // max-width: 40%;  
                margin-bottom: 1rem;  
                width: 100%;
                flex-direction: column;
                display: flex;
            }
            .iframe {
                @include flexCentre;
                width: 100%;
                display: flex;
                height: 350px;
                border: 0;
            }
            .map_Area {
                // max-width: 61%;
                width: 300px;
                min-width: 300px;
                height: auto;
                max-height: 800px;
                @include fontNormal();
                padding: 2rem;
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
        }
        .textContent {
            width: $mobileBoxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: row;
            justify-content: flex-start !important;
    
            .imageWrap {
                max-width: 40%;
                height: auto;    
                position: relative;
    
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 600px;
                    object-fit: cover;
                }
            }
    
            .textWrap_Small {
                max-width: 61%;
                height: auto;
                max-height: 800px;
                @include fontNormal();
                padding: 2rem;
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
    
            .textWrap_Large {
                max-width: 61%;
                height: auto;
                max-height: 1800px;
                @include fontNormal();
                span {
                    white-space: pre-line;
                    line-height: 2;
                }
            }
        }
        
        .imageBox {
            @include fontNormal();
            @include flexCentre();
            width: 100%;
            height: auto;
            flex-wrap:wrap;
            .image_div {
                width:20%;
            }
            img {
                max-width: $mobileBoxWidth;
                min-height: 20vh;
                max-height: 100vh;
            }
        }
        b {
            font-weight: bold;
            @include fontHighlight();
        }
        span {
            display: block;
            white-space: pre-line;
            // font-weight:bold;
            text-align: left;
            width: 100%;
            user-select: none;
            transition: all 1s ease-in-out;
            transition-delay: 1.5s;          
        }
        
    }
}

@include dynamic('1280p') {
    .map_Summary {
        @include flexCentre();
        flex-direction: column;
        width: 100%;
        height: auto;
        margin: 2rem 0 2rem 0;
        
        .subTitle {
            @include fontTitle();
            @include flexCentre();
            font-weight: bold;
            font-family: $fontFamily;
            width: $boxWidth;
            height: auto;
            // margin-bottom: 1rem;  
            span {
                white-space: pre-line;
                b {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
        }
    
        .imageTextContent {
            width: $boxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: row;
            justify-content: flex-start !important;
        }
    
        .text_MapContent {
            @include fontNormal();
            @include flexCentre();
            width: $boxWidth;
            height: auto;
            position: relative;
            flex-direction: row;
            justify-content: flex-start !important;
            margin-top: 2rem;
            .h1{
                margin-bottom: 2rem;  
                display: flex;
                font-size: 25px;
                font-weight: bold;
                font-family: $fontFamily;
                width: $mobileBoxWidth;
                height: auto;
                color : #545454;
                white-space: pre;
            }
            .iframe {
                width : 600px;
                height: 450px;;
                border: 0;
                
            }

            .text_Area {
                // max-width: 40%;  
                width: 40%;
            }
            .map_Area {
                max-width: 61%;
                min-width: 300px;
                height: auto;
                max-height: 800px;
                @include fontNormal();
                padding: 2rem;
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
        }
        .textContent {
            width: $boxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: row;
            justify-content: flex-start !important;
    
            .imageWrap {
                max-width: 40%;
                height: auto;    
                position: relative;
    
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 600px;
                    object-fit: cover;
                }
            }
    
            .textWrap_Small {
                max-width: 61%;
                height: auto;
                max-height: 800px;
                @include fontNormal();
                padding: 2rem;
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
    
            .textWrap_Large {
                max-width: 61%;
                height: auto;
                max-height: 1800px;
                @include fontNormal();
                span {
                    white-space: pre-line;
                    line-height: 2;
                }
            }
        }
        
        .imageBox {
            @include fontNormal();
            @include flexCentre();
            width: 100%;
            height: auto;
            flex-wrap:wrap;
            .image_div {
                width:20%;
            }
            img {
                max-width: $boxWidth;
                min-height: 20vh;
                max-height: 100vh;
            }
        }
        b {
            font-weight: bold;
            @include fontHighlight();
        }
        span {
            display: block;
            white-space: pre-line;
            // font-weight:bold;
            text-align: left;
            width: 100%;
            user-select: none;
            transition: all 1s ease-in-out;
            transition-delay: 1.5s;          
        }
        
    }
    
    .contentBox {
        @include flexCentre();
        flex-direction: column;
        width: 100%;
        height: auto;
        margin: 2rem 0 2rem 0;
    
        .subTitle {
            @include fontTitle();
            @include flexCentre();
            font-weight: bold;
            justify-content: flex-start !important;
            font-family: $fontFamily;
            width: $boxWidth;
            height: auto;
            margin-bottom: 2rem;
            //background: lightgrey;
            
        }
    
        .subTitle2 {
            @include fontTitle();
            @include flexCentre();
            font-weight: bold;
            font-family: $fontFamily;
            width: $boxWidth;
            height: auto;
            //background: lightgrey;
            margin-bottom: 2rem;
        }

        
        .subTitle_half {
            font-weight: bold;
            font-family: $fontFamily;
            font-size: 1.2rem;
            width: $boxWidth;
            height: auto;
            span {
                white-space: pre-line;
                b {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
        }
        
        .textContent {
            @include fontNormal();
            @include flexCentre();
            justify-content: flex-start !important;
            font-family: $fontFamily;
            width: $boxWidth;
            height: 100px;
            margin: 0 0 2rem 0;
            //background: lightgrey;
            
            span {
                line-height: 2;
                white-space: pre-line;
    
                strong {
                    font-weight: bold;
                    @include fontHighlight();
                }
            }
        }
    
        .tabContent {
            @include flexCentre();
            flex-direction: column;
            width: $boxWidth;
            height: auto;
            margin: 0 0 2rem 0;
    
            .tabMenu {
                @include flexCentre();
                font-weight: bold;
                flex-direction: row;
                width: 100%;
                height: 100px;
                min-height: 70px;
                max-height: 100px;
                overflow-x: hidden;
                overflow-y:visible;
                margin: 0 0 2rem 0;
    
                ._disabled {
                    color: grey;
                    cursor: default;
                    user-select: none;
                    pointer-events: none;
                }
    
                .tabIcons {
                    @include flexCentre();
                    @include fontTitle();
                    width: 10%;
    
                    &:nth-child(odd) {
                        cursor: pointer;
                    }
    
                    &:nth-child(even) {
                        user-select: none;
                    }
                }
    
                .tabPseudo {
                    position: relative;
                    width: 50%;
                    height: 100px;
                    max-height: 1000px;
                    
                    @include flexCentre();
    
                    // &:before {
                    //     position: absolute;
                    //     content: '';
                    //     height: 0.5rem;
                    //     left: 0;
                    //     right: 50%;
                    //     background: steelblue;
                    //     z-index: 1;
                    // }
    
                    &:after {
                        position: absolute;
                        content: '';
                        height: 0.5rem;
                        left:0;
                        right: 0;
                        background: white;
                        box-shadow: 0rem 0 0.2rem 0rem rgba(0,0,0,0.5);
                        pointer-events: none;
                        user-select: none;
                        
                    }
                    
                    .pseudoWrap {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include flexCentre();
                        overflow-x: hidden;
                        //transform: translateX(33%);
                        transition: all 0.5s ease-in-out;
    
                        .indicator {
                            position: absolute;
                            @include flexCentre();
                            pointer-events: none;
                            user-select: none;
                            width: 1rem;
                            height: 1rem;
                            background: steelblue;
                            border: 0.2rem solid steelblue;
                            border-radius: 50%;
                            z-index: 2;
                            transition: all 0.5s ease-in-out;
                        }
                        
                        .indicatorBar {
                            position: absolute;
                            @include flexCentre();
                            pointer-events: none;
                            user-select: none;
                            left: -100%;
                            right: 50%;
                            height: 0.5rem;
                            background: steelblue;
                            z-index: 1;
                            transition: all 0.5s ease-in-out;
                        }
    
                        .tabBtn {
                            position: relative;
                            display: inline-block;
                            @include flexCentre();
                            @include fontNormal();
                            margin-bottom: 3.5rem;
                            min-width: 10rem;
                            z-index: 1;
                            cursor: pointer;
                            transition: all 0.5s ease-in-out;
                            
        
                            &:after {
                                cursor: pointer;
                                position: absolute;
                                content: '';
                                width: 1rem;
                                height: 1rem;
                                border-radius: 50%;
                                background: white;
                                z-index: 1;
                                border: 0.2rem solid steelblue;
                                margin-top: 3.5rem;
                                transition: all 0.5s ease-in-out;
                                
                            }
                        }
                    }
                       
                    
                }
            }
    
            .tabTimeline {
                position: relative;
                width: 100%;
                height: auto;
                margin: 0;
                padding: 0 2rem 0 2rem;
                @include flexCentre();
                flex-direction: column;
                // &::after {
                //     content: '';
                //     position: absolute;
                //     left: 50%;
                //     top:0;
                //     bottom:0;
                //     width: 0.1rem;
                //     //background:rgba(120,120,120, 0.6);
                //     background: black;
                // }
    
                ._toLeft {
                    margin-left:50%;
                    border-top-left-radius: 50px;
                    border-bottom-left-radius: 50px;
                }
    
                ._toRight {
                    
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
    
                .timelineContainer {
                    width: 50%;
                    height: auto;
                    @include flexCentre();
                    
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    position: relative;
                    box-sizing: border-box;
    
                    &:nth-child(odd) {
    
                        padding: 0 20px 0 0;
    
                        .timeMonth {
                            border-top-right-radius: 50px;
                            border-bottom-right-radius: 50px;
                        }
    
                        &:after {
                            content: '';
                            position: absolute;
                            height: 0.1rem;
                            background: black;
                            z-index: 1;
                            left:41.7rem;
                            right:0;
                        }
                    }
    
                    &:nth-child(even) {
                        padding: 0 0 0 20px;
    
                        .timeMonth {
                            border-top-left-radius: 50px;
                            border-bottom-left-radius: 50px;
                        }
    
                        &:after {
                            content: '';
                            position: absolute;
                            height: 0.1rem;
                            background: black;
                            z-index: 1;
                            left:0;
                            right:41.7rem;
                        }
                    }
    
                    .timeMonth {
                        width:30%;
                        background: rgba(70, 130, 180,1);
    
                        &:after {
                            content: '';
                            position: absolute;
                            width: 50px;
                            height: 50px;
                            border: 2px solid black;
                            border-radius: 50%;
    
                        }
                    }
    
                    .timeDesc {
                        width: 80%;
                        background: rgba(70, 130, 180,1);
                    }
    
                    span {
                        @include flexCentre();
                        flex-direction: column;
                        width: 100%;
                        height: 100px;
                        @include fontHighlight();
                        line-height: 2;
                        font-weight: bold;
                        color: white;
                    }
                }
    
                .timelineWrap {
                    width: 45%;
                    height: auto;
                    @include flexCentre();
                    animation: timelineAppear 0.5s ease-in-out forwards;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    position:relative;
                    padding: 0rem;
                    opacity: 0;
    
                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            animation-delay: $i * 0.1s
                        }
                    }
    
                    &:hover {
    
                        > .timelineDesc {
                            background: steelblue;
                            color: white;
                        }
    
                        > .timelineMonth {
                            animation: jello 0.9s both;
                        }
                        
                    }
                    
    
                    .timelineMonth {
                        @include flexCentre();
                        @include fontHighlight();
                        position: absolute;
                        //border-radius: 50%;
                        left: -1.5rem;
                        top: -2.5rem;
                        width: 5rem;
                        height: 5rem;
                        background: linear-gradient(135deg,steelblue 0%, rgb(199, 213, 224) 100%);
                        //border: 0.2rem solid steelblue;
                        border-radius: 2rem 2rem 0 2rem;
                        color: white;
                        user-select: none;
                        pointer-events: none;
                    }
    
                    .timelineDesc {
                        @include flexCentre();
                        flex-direction: column;
                        align-items: flex-start !important;
                        padding-left: 5rem;
                        @include fontNormal();
                        white-space: pre-line;
                        line-height: 2;
                        width: calc(100%);
                        height: 5rem;
                        background: white;
                        //border: 0.2rem solid steelblue;
                        margin-left: 1rem;
                        box-shadow: 0rem 0 0.3rem 0.1rem rgba(0,0,0,0.5);
                        user-select: none;
                        pointer-events: none;
                        border-radius: 5px;
                    }
                }
            }
        }
    
        .imageContent {
            width: $boxWidth;
            height: auto;
            @include flexCentre();
            flex-direction: column;
    
            img {
                max-height: 60vh;
                //min-width: 100%;
                max-width: 80%;
            }
            margin: 0 0 1rem 0;
    
        }
    
        .imageTextContent {
            width: $boxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: row;
            justify-content: flex-start !important;

            
    
            .imageWrap {

                .textBox {
                    position: absolute;
                    bottom: 1rem;
                    width: 100%;
                    height: auto;
                    @include flexCentre();
                    
                    span {
                        width: $boxWidth;
                        @include fontNormal();
                        cursor: pointer;
                        font-weight: bold;
                        text-align: center;
                        color: lightslategray;
                
                        &:hover {
                            color: black;
                        }
                    }
                
                    //margin: 0 0 2rem 0;
                }

                @include dynamic('1280p') {
                    max-width: 35%;
                }
                
                @include dynamic('1920p') {
                    max-width: 30%;
                };

                @include dynamic('2560p') {
                    max-width: 40%;
                };

                height: auto;    
                position: relative;
    
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 600px;
                    object-fit: cover;
                }
            }
    
            .textWrap {
                max-width: 61%;
                height: auto;
                max-height: 800px;
                @include fontNormal();
                padding: 2rem;
    
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
        }

        .imageTextContent_Career {
            width: $boxWidth;
            height: auto;
            position: relative;
            @include flexCentre();
            flex-direction: row;
            justify-content: flex-start !important;
            
            .imageWrap {

                @include dynamic('1280p') {
                    max-width: 25%;
                }
                
                @include dynamic('1920p') {
                    max-width: 55%;
                };

                @include dynamic('2560p') {
                    max-width: 55%;
                };

                height: auto;    
                position: relative;
    
                img {
                    margin-left: 15%;
                    display: block;
                    max-width: 90%;
                    max-height: 800px;
                    object-fit: cover;
                }
            }
    
            .textWrap {
                max-width: 40%;
                height: auto;
                max-height: 800px;
                @include fontNormal();
                b {
                    font-weight: bold;
                    @include fontHighlight();
                }
                // padding: 2rem;
                span {
                    white-space: pre-line;
                    line-height: 2;
                    
                    b {
                        font-weight: bold;
                        @include fontHighlight();
                    }
                }
            }
    
            
        }




        .cardContainer {
            width: calc(100% - 4rem);
            height: auto;
            min-height: 400px;
            
            @include flexCentre();
            flex-direction: row;
    
            .cardUnit {
                @include flexCentre();
                flex-direction: column;
                width: 20%;
                height: 100%;
                min-height: 400px;
                margin: 0 2rem 0 2rem;
                box-sizing: border-box;
                cursor: pointer;
    
                &:hover {
                    & > .cardDesc {
    
                        background: steelblue;
                        color: white;
    
                        &:after {
                            background: steelblue;
                        }
                    } 
                }
                
    
                .cardImg {
                    user-select: none;
                    pointer-events: none;
                    width: 100%;
                    height: 80%;
                    box-sizing: border-box;
    
                    img {
                        width: 100%;
                        max-height: 320px;
                        object-fit: cover;
                        border: none;
                        display: block;
                    }

                    picture {
                        width: 100%;
                        max-height: 320px;
                        object-fit: cover;
                        border: none;
                        display: block;
                    }
                    
                }
    
                .cardDesc {
                    user-select: none;
                    pointer-events: none;
                    width: 100%;
                    height: 20%;
                    @include flexCentre();
                    flex-direction: column;
                    align-items: flex-start !important;
                    position: relative;
                    padding: 1.5rem;
                    box-sizing: border-box;
                    border: none;
                    transition: background-color 0.3s ease-in-out;
    
                    span {
                        margin-bottom: 1rem;
                        &:nth-child(1) {
                            @include fontHighlight();
                            font-weight: bold;
                        }
                        &:nth-child(2) {
                            @include fontNormal();
                        }
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        width: 2rem;
                        height: 2rem;
                        top: -1rem;
                        left: 2rem;
                        background: white;
                        transform: rotate(45deg);
                        transition: background-color 0.3s ease-in-out;
                        
                    }
                    
                }
            }
        }
    
    }
    
    .imageBox {
        @include flexCentre();
        width: 100%;
        height: auto;
        flex-wrap:wrap;
        
        img {
            max-width: $boxWidth;
            min-height: 40vh;
            max-height: 50vh;
        }

        picture {
            max-width: $boxWidth;
            min-height: 40vh;
            max-height: 50vh;

            img, source {
                width: 100%;
                height: 100%;
            }
        }

        margin: 0 0 2rem 0;
    }
    
    .modalWrapper {
        @include flexCentre();
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100vh;
        top: 0;
        background: rgba(120,120,120, 0.6); 
    
        .modalBody {
            background: rgba(255,255,255,1);
            width: 25%;
            height: auto;
            border-radius: 16px;
            user-select: none;
            
            img {
                max-height: 50vh;
                max-width: 100%;
            }
        }
    }
    
    .cardSlide {
        width: 100%;
        height: calc(100vh - 100px);
        background: grey;
        position: relative;
        overflow: hidden;
    
        .cardBox {
            position: absolute;
            //transition: all 0.5s ease-in-out;
            white-space:nowrap;
            transform: translate3d(0,0,1px);
            backface-visibility: hidden;
    
            .slider {
                display: inline-flex;
                position: relative;
                width: 100vw;
                height: 100%;
    
                .titleDiv {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    @include flexCentre();
                    background: rgba(0,0,0,0.6);
    
                    span {
                        display: block;
                        white-space: pre-line;
                        font-weight:bold;
                        text-align: center;
                        width: 100%;
                        @include fontXXXL();
                        color:white;
                        user-select: none;               
                        transition: all 1s ease-in-out;
                        transition-delay: 1s;
                        //opacity: 0;
                    }
                }
                
    
                img {
                    display: inline-flex;
                    line-height: 2;
                    width: 100%;
                    min-height: calc(100vh - 100px);
                    max-height: calc(100vh - 100px);
                    object-fit:cover;  
                }

                picture {
                    display: inline-flex;
                    line-height: 2;
                    width: 100%;
                    min-height: calc(100vh - 100px);
                    max-height: calc(100vh - 100px);
                    object-fit:cover;  
                }
            }    
    
            
        }
    
        .iconWrap {
            @include flexCentre();
            position: absolute;
            top: calc(50% - 2rem);
            width: 70px;
            height: 70px;
            z-index:1;
            //background: white;
    
            &:nth-child(1) {
                left: 0;
            }
    
            &:nth-child(2) {
                right: 0;
            }
    
            .cardIcon {
                color: white;
                width: 100%;
                height: 100%;
                cursor: pointer;
                
                &:hover {
                    color: rgba(120,120,120, 0.6);
                }
            }
        }
        
        
    
        .cardTitle {
            @include flexCentre();
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(0,0,0,0.6);
    
            span {
                text-align: center;
                width: 50%;
                font-size: 70px;
                color:white;
            }
    
        }
    }
}



@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes timelineAppear {
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }

    100% { 
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes jello {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
}